import React from 'react';
import './PaymentThankyou.scss';

const PaymentThankyou: React.FC = () => {
    return (
        <div className="thank-you-message">
            <h2>Thank you for choosing BPAY</h2>
        </div>
    );
};

export default PaymentThankyou;