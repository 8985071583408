import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import HQApi from '../../api/HQApi';
import Brands from '../../utils/Brands';
import { PaymentLink as PaymentLinkDto, PayResponse } from '../Models/Models';

import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import PaymentLinkDetails from '../PaymentLinkDetails/PaymentLinkDetails';
import PaymentLinkInvalid from '../PaymentLinkInvalid/PaymentLinkInvalid';
import PaymentResult from '../PaymentResult/PaymentResult';

import './PaymentLink.scss';
import PaymentThankyou from '../PaymentThankyou/PaymentThankyou';

interface IState {
    isLoading: boolean;
    paymentLink: PaymentLinkDto | undefined;
    paymentAmount: number | undefined;
    paymentResponse: PayResponse | undefined;
    adyenComponentConfiguration: any;
}

interface MatchParams {
    paymentLinkId: string;
}

class PaymentLink extends React.Component<RouteComponentProps<MatchParams>, IState> {

    state: IState = {
        isLoading: true,
        paymentLink: undefined,
        paymentAmount: undefined,
        paymentResponse: undefined,
        adyenComponentConfiguration: undefined
    }

    componentDidMount() {
        this.load();
    }

    load = async () => {
        const { history, match } = this.props;
        try {
            await this.loadPaymentLink();
            if (this.state.paymentLink) {
                 await this.loadAdyenComponentConfiguration(this.state.paymentLink.divisionId);
            }

            this.setState({
                isLoading: false
            },
            () => {
                const { paymentLink } = this.state;
                if (!paymentLink || !paymentLink.isValid)
                    history.push(`${match.url}/invalid`);
            });
        } catch {
            this.setState({
                isLoading: false
            },
            () => history.push(`${match.url}/invalid`));
        }
    }

    loadPaymentLink = async () => {
        const { match: { params: { paymentLinkId }} } = this.props;
        const response = await HQApi.get(`api/v2/paymentlinks/${paymentLinkId}`);
        this.setState({
            paymentLink: response.data
        });
    }

    loadAdyenComponentConfiguration = async (divisionId: number) => {
        const response = await HQApi.get(`api/v1/creditcards/divisions/${divisionId}/componentconfiguration`);
        this.setState({
            adyenComponentConfiguration: response.data
        });
    }

    onPayment = (paymentAmount: number, paymentResponse?: PayResponse) => {
        const { history, match: { params: { paymentLinkId }} } = this.props;
        this.setState({
            paymentAmount: paymentAmount,
            paymentResponse: paymentResponse
        },
        () => history.push(`/${paymentLinkId}/result`));
    }

    render() {
        const { match: { path } } = this.props;
        const { isLoading, paymentLink, paymentAmount, paymentResponse, adyenComponentConfiguration } = this.state;

        if (isLoading)
            return <LoadingIndicator loadingMessage="Loading Please Wait..." />

        const themeName = Brands.getThemeFor(paymentLink?.divisionId, paymentLink?.brandId);
        const brandName = paymentLink?.brandName || 'Ignite Travel Group';
        const brandPhone = paymentLink?.brandPhone;

        return (
            <div className={`${themeName} payment-link`}>
                <header>
                    <nav className="navbar">
                        <div className="container is-max-desktop">
                            <div className="level is-flex-grow-1">
                                <div className="level-left">
                                    <div className="level-item">
                                        <img alt={brandName}
                                             src={process.env.PUBLIC_URL + "/images/" + themeName + "/logo.svg"}>
                                        </img> 
                                    </div> 
                                </div>
                                { brandPhone &&
                                    <div className="level-right"> 
                                        <div className="level-item"> 
                                            <a className="phone"  
                                                href={"tel:" + brandPhone}> 
                                                <i className="fa fa-fw fa-phone" aria-hidden="true"></i> 
                                                {brandPhone}
                                            </a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </nav>
                </header>
                <main>
                    <div className="container is-max-desktop">
                        <Switch>
                            <Route exact path={`${path}/invalid`} render={(props) => (
                                <PaymentLinkInvalid {...props}
                                                    paymentLink={paymentLink}>
                                </PaymentLinkInvalid>
                            )}/>
                            <Route exact path={`${path}/result`} render={(props) => paymentLink && (
                                <PaymentResult {...props}
                                            paymentLink={paymentLink || {}}
                                            paymentAmount={paymentAmount}
                                            paymentResponse={paymentResponse}>
                                </PaymentResult>
                            )}/>
                            <Route exact path={`${path}/thankyou`} render={(props) => paymentLink && (
                                <PaymentThankyou>
                                </PaymentThankyou>                                         
                            )}/>
                            <Route exact render={(props) => paymentLink && adyenComponentConfiguration && (
                                <PaymentLinkDetails {...props}
                                                    paymentLink={paymentLink}
                                                    adyenComponentConfiguration={adyenComponentConfiguration}
                                                    onPayment={this.onPayment}>
                                </PaymentLinkDetails>
                            )}/>
                        </Switch>
                    </div>
                </main>
                <footer>
                    <div className="container is-max-desktop has-text-centered">
                        <p>
                            <span>© Ignite Travel Group</span>
                            <span>All rights reserved</span>
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.ignitetravel.com/ignite-travel-privacy-policy/">
                                Privacy Policy 
                            </a>
                        </p>
                    </div>
                </footer>
            </div>
        )
    }
}

export default PaymentLink;